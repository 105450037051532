<template>
        <form class="row g-3 login" @submit.prevent="login">
            <div class="col-12">
                <!-- <label for="email" class="form-label">Email</label> -->
                <input type="email" class="form-control" placeholder="Email" v-model="email" required>
            </div>
            <div class="col-12">
                <!-- <label for="password" class="form-label">Password</label> -->
                <input type="password" class="form-control" placeholder="Password" v-model="password" required>
            </div>
            <div class="col-12">
                <button :disabled="isLoad" type="submit" class="btn btn-primary w-100">
                    <span v-if="isLoad" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Sign in</span>
                </button>
            </div>
            <div v-show="error" class="alert alert-dismissible alert-danger">*{{ error }}</div>
            <!-- <p v-show="error" class="text-danger">{{ error }}</p> -->
        </form>
</template>

<script>
import firebase from '@/firebase'
export default {
    data() {
        return {
            email: '',
            password: '',
            error: '',
            isLoad: false
        }
    },
    methods: {
        async login() {
            this.isLoad = true
            try {
                await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                this.email = ''
                this.password = ''
                this.isLoad = false
            } catch (err) {
                const errorCode = err.code
                this.error = errorCode
                this.isLoad = false
            }
        }
    }
}
</script>

<style scoped>
.login {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

form {
    width: 80%;
    max-width: 480px;
}

input:focus {
    box-shadow: none;
}
</style>